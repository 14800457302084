import { Link } from "react-router-dom";

import React, { useState, useEffect } from 'react';
import { FaFileAlt, FaLaptopCode, FaUserGraduate } from "react-icons/fa";
import { RiComputerLine } from "react-icons/ri";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { GiDiscussion } from "react-icons/gi";
import OwlCarousel from 'react-owl-carousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';


import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home'; // Example icon
import InfoIcon from '@mui/icons-material/Info'; // Example icon
import SettingsIcon from '@mui/icons-material/Settings'; // Example icon


const scrollToTop = () => {
    window.scrollTo(0, 0);
};
export default function It() {
    const [value, setValue] = React.useState('1');

    const [selected, setSelected] = useState(0);


    const toggle = (i) => {
        // Toggle the clicked FAQ, including the first one
        setSelected(selected === i ? null : i);
    };

    const theme = createTheme({
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        // backgroundColor: 'white',
                        fontWeight: '700',
                        color: '#fff',
                        '&.Mui-selected': {
                            // backgroundColor: 'white',
                            color: 'orange',
                        },
                        '&:hover': {
                            // backgroundColor: '#f5f5f5', // Optional: change color on hover
                        }
                    },
                },
            },
        },
    });

    const faqs = [
        { question: 'Who we are?', answer: 'We are the leading USA-based Web App Design & Development Company, creating scalable web applications, integrated with modern design frameworks and user-centric UI/UX. Hire web app developers to get top-notch quality web development services to boost performance. Explore more about us, and connect with our tech experts to have better insights.' },
        { question: 'What services do Robo Analytics provide?', answer: 'We offer digital marketing, web app development, UI/UX designs, AI, ML apps, cloud-based, graphic design, blockchain, and mobile app development.' },
        { question: 'What are the industries you work for?', answer: 'Robo Analytics, a top-ranked Web App Design & Development Company that delivers the following services with a well-versed and experienced team of experts: Real Estate, Healthcare, FoodTech, Travel & Hospitality, Automotive, Education, Retail & E-Commerce, Transportation, FinTech.' },
        { question: 'What approaches do you follow to deliver the project?', answer: 'Our team of web app developers adheres to the Agile Methodology that helps to select the right set of software for your business project, depending on the scope, and requirements, and supports us to deliver high-quality custom web app development solutions. 1-Ideation, 2-Development, 3-Testing, 4-Deployment, 5-Operations' },
        { question: 'How long does it take to develop and design a web app or mobile app?', answer: 'Our operations are time-bound and yet depending on the complexity, scope, and size of the project, the time frame can be decided. Through the help of our dedicated team of experts, we prioritize executing each project by ensuring quality and meeting all the requirements of the clients as well.' },
        { question: 'How much cost does it take for web app development?', answer: 'Depending on the project’s complexity, scope, size, and other essential factors help to decide the cost of web app development. To know the details of the cost structure for web app development, feel free to connect with our tech experts.' }
    ];

    const tabItems = [
        {
            label: 'UI/UX',
            value: '1',
            items: [
                { imgSrc: '/img/Figma.png', description: 'Figma' },
                { imgSrc: '/img/AdobeXd.png', description: 'AdobeXD' },
                { imgSrc: '/img/AdobeAI.png', description: 'adobeAI' },
                { imgSrc: '/img/AdobePhotoshop.png', description: 'photoshop' },
                { imgSrc: '/img/Sketch.png', description: 'Sketch' },
            ],
        },
        {
            label: 'Mobile',
            value: '2',
            items: [
                // { imgSrc: '/img/Flutter.png', description: 'Flutter' },
                { imgSrc: '/img/ReactNative.png', description: 'React Native' },
                { imgSrc: '/img/swift.png', description: 'Swift' },
                { imgSrc: '/img/Firebase.png', description: 'Firebase' },
                { imgSrc: '/img/Kotlin.png', description: 'Kotlin' },
                { imgSrc: '/img/AndroidStudio.png', description: 'AndroidStudio' },

            ],
        },
        {
            label: 'Frontend',
            value: '3',
            items: [
                { imgSrc: '/img/HTML.png', description: 'HTML' },
                { imgSrc: '/img/css.png', description: 'CSS' },
                { imgSrc: '/img/js.png', description: 'Javascript' },
                // { imgSrc: '/img/Bootstarp.png', description: 'Bootstrap' },
                { imgSrc: '/img/ReactNative.png', description: 'React Js' },
                // { imgSrc: '/img/Vue.png', description: 'Vue' },
                // { imgSrc: '/img/angular.png', description: 'angular' },
                { imgSrc: '/img/ReactNative.png', description: 'Next Js' },



            ],
        },
        {
            label: 'Backend',
            value: '4',
            items: [
                { imgSrc: '/img/PHP.png', description: 'PHP' },
                { imgSrc: '/img/Java.png', description: 'Java' },
                { imgSrc: '/img/Python.png', description: 'Python' },
                { imgSrc: '/img/NodeJS.png', description: 'NodeJS' },
                { imgSrc: '/img/CSharp.png', description: 'C#' },
            ],
        },
        {
            label: 'Databases',
            value: '5',
            items: [
                { imgSrc: '/img/MySQL.png', description: 'MySQL' },
                { imgSrc: '/img/SQLite.png', description: 'SQLite' },
                { imgSrc: '/img/MongoDB.png', description: 'MongoDB' },
                { imgSrc: '/img/OracleDB.png', description: 'OracleDB' },
                { imgSrc: '/img/PostgreSQL.png', description: 'PostgreSQL' },
            ],
        },
        {
            label: 'Framework',
            value: '6',
            items: [
                { imgSrc: '/img/DJango.png', description: 'DJango' },
                { imgSrc: '/img/Flask.png', description: 'Flask' },
                { imgSrc: '/img/SpringMVC.png', description: 'SpringMVC' },
                { imgSrc: '/img/laravel.png', description: 'laravel' },
                { imgSrc: '/img/codeigniter.png', description: 'Codeigniter' },
            ],
        },
        {
            label: 'AI',
            value: '7',
            items: [
                { imgSrc: '/img/Ai.png', description: 'AI ' },
                { imgSrc: '/img/prompt.png', description: 'Prompt' },
                { imgSrc: '/img/ml.png', description: 'ML' },
                { imgSrc: '/img/ai4.png', description: 'AI Content 4' },
                { imgSrc: '/img/ai5.png', description: 'AI Content 5' },
            ],
        },
    ];


    useEffect(() => {
        scrollToTop();
    }, []);

    // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    //     setValue(newValue);
    // };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const testimonials = [
        {
            id: 1,
            profession: "Real Estate",
            defaultImgSrc: "img/cart-stores.cf9b5265.png",
            hoverImgSrc: "img/building-hover.2471efa7.png"
        },
        {
            id: 2,
            profession: "FoodTech",
            defaultImgSrc: "img/Department-stores.f22280fb.png",
            hoverImgSrc: "img/deprt-hover.873619e7.png"
        },
        {
            id: 3,
            profession: "Edutech",
            defaultImgSrc: "img/education.2253177c.png",
            hoverImgSrc: "img/education-hover.d0dd48cd.png"
        },
        {
            id: 4,
            profession: "Healthcare",
            defaultImgSrc: "img/Grocers.a69ccc5b.png",
            hoverImgSrc: "img/med-hover.2afe652d.png"
        },
        {
            id: 5,
            profession: "Retail & E-commerce",
            defaultImgSrc: "img/shpocart.3b32efe2.png",
            hoverImgSrc: "img/shapocart-hover.8f16eaa1 (1).png"
        },
        {
            id: 6,
            profession: "Automotive",
            defaultImgSrc: "img/Supermarkets.de730b32.png",
            hoverImgSrc: "img/superm-hover.5d91868a.png"
        },
        {
            id: 7,
            profession: "Travel & Hospitality",
            defaultImgSrc: "img/travel.c222aeaf.png",
            hoverImgSrc: "img/tarvel-hover.2615f450.png"
        },

        {
            id: 8,
            profession: "FinTech ",
            defaultImgSrc: "img/tech.767f5969.png",
            hoverImgSrc: "img/tech-hover.a2301c8c.png"
        }
    ];



    const testimonial_carousel = ({
        autoplay: false,
        smartSpeed: 1000,
        margin: 25,
        dots: true,
        loop: true,
        center: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 3
            },
            992: {
                items: 4
            },

        }

    });

    const testimonialSettings = {
        infinite: true,
        speed: 900,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        arrows: true,
        fade: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    }


    const [filter, setFilter] = useState("*"); // Default filter is set to show all items
    const [filteredItems, setFilteredItems] = useState([]);

    const services = [
        {
            title: "AI Research and Development",
            icon: "fa-robot",
            description: "AI tool and software development involves creating intelligent systems and applications that can perform tasks traditionally requiring human intelligence",
            link: "/ai",
            delay: "0.3s"
        },
        {
            title: "Cloud Solutions",
            icon: "fa-cloud",
            description: "We Deliver Cloud Solutions across major cloud platforms such as IBM Cloud, Google Cloud AI, Azure, and AWS.",
            link: "/cloud-solutions",
            delay: "0.6s"
        },
        {
            title: "Mobile App Development",
            icon: "fa-mobile",
            description: "Mobile app development involves the creation of software applications specifically designed to run on mobile devices such as smartphones and tablets.",
            link: "/mobile-app",
            delay: "0.6s"
        },
        {
            title: "Web & Software Development",
            icon: "fa-desktop",
            description: "Our web development team makes sure that they stay up-to-date with the latest technological developments in this area so as to offer the best solutions in terms of technology.",
            link: "/web-development",
            delay: "0.1s"
        },
        {
            title: "UX/UI Design",
            icon: "fa-laptop-code",
            description: "It has turned our UX/UI designers into experts in creating the most elegant and appealing UX Web Design.",
            link: "/ui-ux",
            delay: "0.1s"
        },
        {
            title: "Digital Marketing",
            icon: "fa-bullhorn",
            description: "Digital Marketing is all about creating an online presence and reaching out to your consumer’s screen to sell your products and services.",
            link: "/digital-marketing",
            delay: "0.3s"
        }
    ];


    // Sample portfolio items
    const portfolioItems = [
        { name: "Project 1", category: "first", image: "img/It/portfolio-1.jpg" },
        { name: "Project 2", category: "second", image: "img/It/portfolio-2.jpg" },
        { name: "Project 3", category: "first", image: "img/It/portfolio-3.jpg" },
        { name: "Project 4", category: "second", image: "img/It/portfolio-4.jpg" },
        { name: "Project 5", category: "first", image: "img/It/portfolio-5.jpg" },
        { name: "Project 6", category: "second", image: "img/It/portfolio-6.jpg" },
    ];



    const servicesLeft = [
        {
            title: 'Experience',
            description: 'Leverage years of industry experience to deliver high-quality software solutions that meet your business needs.',
            icon: 'img/icon-1.svg',
        },
        {
            title: 'Products',
            description: 'Innovative software products designed to enhance productivity and streamline operations for businesses of all sizes.',
            icon: 'img/icon-2.svg',
        },
        {
            title: 'Approach',
            description: 'A client-centric approach that emphasizes collaboration and agile methodologies for effective project delivery.',
            icon: 'img/icon-3.svg',
        },
    ];

    const servicesRight = [
        {
            title: 'Pricing',
            description: 'Transparent and competitive pricing models tailored to provide maximum value for your investment in software solutions.',
            icon: 'img/icon-4.svg',
        },
        {
            title: 'Delivery',
            description: 'Timely delivery of software solutions with a focus on quality assurance and rigorous testing protocols.',
            icon: 'img/icon-5.svg',
        },
        {
            title: 'Support',
            description: 'Comprehensive support services including maintenance, troubleshooting, and continuous improvements to ensure optimal performance.',
            icon: 'img/icon-6.svg',
        },
    ];



    // Function to handle filter selection
    const handleFilter = (category) => {
        setFilter(category);
    }

    useEffect(() => {
        if (filter === "*") {
            setFilteredItems(portfolioItems);
        } else {
            const filtered = portfolioItems.filter(item => item.category === filter);
            setFilteredItems(filtered);
        }
    }, [filter]);
    const [hoveredIndex, setHoveredIndex] = useState(null);


    return (
        <>

            <div className="container-fluid pt-5 position-relative hero-header overlay-bottom" style={{ marginBottom: "50px", height: "752px" }}>
                <div className="container pt-5 item">
                    <div className="row g-5 pt-5">
                        <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                            <a href="https://bhattacharjeegroup.com/" target="_blank"> <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">BG GROUP</div></a>
                            <h1 className="display-4 text-white mb-4 animated slideInRight">Robo Analytics</h1>
                            <p className="text-white mb-4 animated slideInRight">In an era defined by technological advancement, we harness the power of artificial intelligence to develop cutting-edge software solutions. Our team of skilled engineers and developers is committed to creating intelligent software that drives efficiency and innovation across industries.</p>

                        </div>
                        <div className="col-lg-6 align-self-end text-center text-lg-end">
                            <img className="img-fluids" src="../img/baner2.webp" alt="" />
                        </div>
                    </div>
                </div>
            </div>




            {/* Service Start */}

            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                        <h2 className="mt-2">What Solutions We Provide</h2>
                        <p>We deliver innovative, reliable software solutions tailored to your business. With expert teams, transparent pricing, and ongoing support.</p>
                        <span className="y-us-title-border"></span>
                    </div>
                    <div className="row g-4">
                        {services.map((service, index) => (
                            <div key={index} className={`col-lg-4 col-md-6 wow zoomIn`} data-wow-delay={service.delay}>
                                <div className="service-item-it d-flex flex-column justify-content-center text-center rounded">
                                    <div className="service-icon flex-shrink-0">
                                        <i className={`fa ${service.icon} fa-2x`}></i>
                                    </div>
                                    <h5 className="mb-3">{service.title}</h5>
                                    <p>{service.description}</p>
                                    <Link className="btn px-3 mt-auto mx-auto" to={service.link}>Read More</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* ceo */}
              <div className="container-fluid  py-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            {/* <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Founder & CEO</div> */}
                            <h1 className="mb-4">Meet Our Founder & CEO</h1>
                            <p className="mb-4">Meet our Founder & CEO, who brings over 13 years of invaluable expertise to our organization. With a profound understanding of the industry cultivated over more than a decade, our leader has navigated the complexities of this field with unparalleled vision and determination. Their strategic insights and leadership have propelled our company to new heights, shaping our journey towards continued success.</p>
                            {/* <Link className="btn btn-primary rounded-pill px-4" to="">Read More</Link> */}
                        </div>
                        <div className="col-lg-6">
                            <div className="row g-6">
                                <div className="col-md-10">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                                            <div className="team-item  text-center rounded p-4 pt-0"style={{backgroundColor:"#faf5f5"}}>
                                                <img className="img-fluid rounded-circle p-4" src="img/team-1.png" alt="" />
                                                <h5 className="mb-0">Abhishek Bhattacharjee</h5>
                                                <small>Founder & CEO</small>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-facebook-f"></i></Link>
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-twitter"></i></Link>
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-instagram"></i></Link>
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-linkedin-in"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Portfolio Start */}

            <div className="container-xxl py-2 ">
                <div className="container px-lg-5">
                    <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                        {/* <h6 className="position-relative d-inline text-primary ps-4">Our Projects</h6> */}
                        <h2 className="mt-2">Recently Launched Our AI Tools</h2>
                        <p>We deliver innovative, reliable software solutions tailored to your business. With expert teams, transparent pricing, and ongoing support.</p>
                        <span className="y-us-title-border"></span>
                    </div>
                    {/* <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="col-12 text-center">
                            <ul className="list-inline mb-5" id="portfolio-flters">
                                <li className={`btn px-3 pe-4 ${filter === "*" ? 'active' : ''}`} onClick={() => handleFilter("*")}>All</li>
                                <li className={`btn px-3 pe-4 ${filter === "first" ? 'active' : ''}`} onClick={() => handleFilter("first")}>Design</li>
                                <li className={`btn px-3 pe-4 ${filter === "second" ? 'active' : ''}`} onClick={() => handleFilter("second")}>Development</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row g-4 portfolio-container ">
                       
                        {filteredItems.map((item, index) => (
                            <div className="col-lg-4 col-md-6 portfolio-item wow zoomIn" data-wow-delay={`${index * 0.3 + 0.1}s`} key={index}>
                                <div className="position-relative rounded overflow-hidden">
                                    <img className="img-fluid w-100" src={item.image} alt={item.name} />
                                    <div className="portfolio-overlay">
                                        <a className="" to={item.image} data-lightbox="portfolio"></a>
                                        <div className="mt-auto">
                                            <small className="text-white"><i className="fa fa-folder me-2"></i>{item.category === "first" ? "Design" : "Development"}</small>
                                            <a className="h5 d-block text-white mt-1 mb-0" to="">{item.name}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>

            {/* videos */}

            <div className="container-xxl py-2  px-0 wow zoomIn" data-wow-delay="0.1s">
                <div className="row g-0">
                    <div className="col-md-6 bg-dark d-flex align-items-center">
                        <div className="p-5">
                            <h6 className="section-title-hotel text-start text-white text-uppercase mb-3">Linkometic</h6>
                            <h1 className="text-white mb-4">Our Linkometic App</h1>
                            <p className="text-white mb-4">
                                LiNKoMaTiC streamlines link building with AI-driven tools for bulk Google scraping, relevant site ranking, and efficient team management.
                                Tailored for agencies, it empowers link builders to work faster and more effectively.
                            </p>
                            <a href="https://linkomatic.ai/" target="_blank" className="btn btn-primary py-md-3 px-md-5 me-3">Read More</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="video_it">
                            <button type="button" className="btn-play" data-bs-toggle="modal" data-src="img/It/big_tool.mp4" data-bs-target="#videoModal">
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content rounded-0">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Linkometic</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="ratio ratio-16x9">
                                    <video id="video" controls>
                                        <source src="img/It/big_tool.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Testimonial Start */}

            {/* <div className="container-xxl testimonial-it py-5 my-5 wow fadeInUp" data-wow-delay="0.1s" style={{ backgroundColor: "#11409e" }}>
                <div style={{ textAlign: 'center', marginBottom: '10px', color: '#fff' }}>
                    <h1 style={{ color: '#fff' }}>Solutions For Every Industry</h1>
                </div>
                <div className="container py-5 px-lg-5">
                    <OwlCarousel className="owl-carousel testimonial-carousel owl-theme" {...testimonial_carousel}>
                        {testimonials.map((testimonial, index) => (
                            <div
                                key={index}
                                className="testimonial-item bg-transparent rounded text-white p-4"
                            >
                                <div className="" style={{ display: "flex", flexDirection: "column", height: "22vh", alignItems: "center" }}>
                                    <img
                                        className="img-fluid flex-shrink-0"
                                        src={testimonial.defaultImgSrc}
                                        style={{
                                            width: "104px",
                                            height: "114px",
                                            filter: "brightness(0) invert(1)",
                                        }}
                                        alt={testimonial.profession}
                                    />
                                </div>
                                <div className="" style={{ textAlign: "center", fontSize: "20px" }}>
                                    <small>{testimonial.profession} </small>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div> */}


            {/* Testimonial Start */}

            <div className="container-xxl testimonial-it py-5 my-5 wow fadeInUp" style={{ backgroundColor: "#042668" }}>
                <div style={{ textAlign: 'center', marginBottom: '10px', color: '#fff', display: 'flex', justifyContent: 'center' }}>
                    <h1 style={{ color: '#fff', display: 'flex' }}>Solutions For Every <span style={{ marginLeft: '10px', color: '#a1cbff' }}>Industry</span> </h1>
                </div>
                <div className="container py-5 px-lg-5">
                    <Slider {...testimonialSettings}>
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className={`testimonial-item bg-transparent rounded text-white p-4 ${hoveredIndex === index ? "slider-hover" : ""}`}>
                                <div
                                    style={{ display: "flex", flexDirection: "column", height: "22vh", alignItems: "center" }}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                >
                                    <img
                                        className="img-fluid flex-shrink-0"
                                        src={hoveredIndex === index ? testimonial.hoverImgSrc : testimonial.defaultImgSrc}
                                        style={{
                                            width: "104px",
                                            height: "114px",
                                            filter: hoveredIndex === index ? "none" : "brightness(0) invert(1)",
                                        }}
                                        alt={testimonial.profession}
                                    />
                                </div>
                                <div style={{ textAlign: "center", fontSize: "20px" }}>
                                    <small>{testimonial.profession}</small>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>




            {/* process */}

            <div className="y-us-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-md-offset-2 col-sm-12 col-xs-12">
                            <div className="y-us-head">

                                <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                                    {/* <h6 className="position-relative d-inline text-primary ps-4">Our Projects</h6> */}
                                    <h2 className="mt-2">Why choose us</h2>
                                    <p>We deliver innovative, reliable software solutions tailored to your business. With expert teams, transparent pricing, and ongoing support.</p>
                                    <span className="y-us-title-border"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {/* Left Services */}
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="y-us-content">
                                <div className="service-3">
                                    {servicesLeft.map((service, index) => (
                                        <div className="service-box" key={index}>
                                            <div className="clearfix">
                                                <div className="iconset_right">
                                                    <span className="glyphicon glyphicon-cog icon">
                                                        <img src={service.icon} />
                                                    </span>
                                                </div>
                                                <div className="y-us-content_left">
                                                    <h4>{service.title}</h4>
                                                    <p>{service.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Image Section */}
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="why_choose_banner">
                                <img src="img/features.jpg" alt="Why Choose Us" />
                            </div>
                        </div>

                        {/* Right Services */}
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="y-us-contentbox">
                                <div className="service-3">
                                    {servicesRight.map((service, index) => (
                                        <div className="service-box" key={index}>
                                            <div className="clearfix">
                                                <div className="iconset_left">
                                                    <span className="glyphicon glyphicon-cog icon">
                                                        <img src={service.icon} alt={service.title} />
                                                    </span>
                                                </div>
                                                <div className="y-us-content_right">
                                                    <h4>{service.title}</h4>
                                                    <p>{service.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*use technology  */}

            <div className="container-xxl testimonial-it py-5 my-5 wow fadeInUp" data-wow-delay="0.1s" style={{ backgroundColor: "#042668" }}>
                <div style={{ textAlign: 'center', marginBottom: '10px', color: '#fff', display: 'flex', justifyContent: 'center' }}>
                    <h1 style={{ color: '#fff', display: 'flex' }}>
                        We use technology that is
                        <span style={{ marginLeft: '10px', marginRight: '10px', color: '#a1cbff' }}>built</span>
                        to last
                    </h1>
                </div>

                <div className="container py-5 px-lg-5 use_tech">
                    <ThemeProvider theme={theme}>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-around' }}>
                                    <TabList
                                        onChange={handleChange}
                                        aria-label="tech tabs example"
                                        sx={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 2, textAlign: 'center' }}
                                    >
                                        {tabItems.map((tab) => (
                                            <Tab key={tab.value} label={tab.label} value={tab.value} />
                                        ))}
                                    </TabList>
                                </Box>

                                {tabItems.map((tab) => (
                                    <TabPanel key={tab.value} value={tab.value}>
                                        <div className="testimonial-item bg-transparent rounded p-4" style={{ backgroundColor: '#fff' }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-around',
                                                flexWrap: 'wrap',
                                                //   margin: '0px 15px', 
                                                gap: '20px' // Add gap to maintain equal space between items 
                                            }}>
                                                {tab.items.map((item, index) => (
                                                    <div key={index} style={{ textAlign: 'center', marginBottom: '20px', padding: '32px' }} className="use_tab">
                                                        <img
                                                            className="img-fluid"
                                                            src={item.imgSrc}
                                                            alt={tab.label}
                                                            style={{
                                                                width: '45px',
                                                                height: '45px',
                                                                marginBottom: '10px',
                                                                objectFit: 'cover', // Ensures the image fits nicely within the container
                                                                filter: 'grayscale(100%) brightness(0.4)'

                                                            }}
                                                        />
                                                        <div style={{ fontSize: '16px', color: '#666666' }}>{item.description}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </TabPanel>

                                ))}
                            </TabContext>
                        </Box>
                    </ThemeProvider>
                </div>
            </div>

            {/* Faq */}

            <div className="faq-container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow zoomIn" data-wow-delay="0.5s">
                        <h1>Frequently Asked Questions</h1>
                        <p>Frequently asked question (FAQ) pages to find answers.</p>
                        {/* <img src="img/faq.jpg" style={{ width: "40vw", marginLeft: "-65px" }} /> */}
                    </div>

                    <div className="col-lg-6 fadeInUp" data-wow-delay="0.2s">
                        <div className="faq">
                            {faqs.map((item, i) => (
                                <div className="faq-item" key={i}>
                                    <div className="faq-question" onClick={() => toggle(i)}>
                                        <h3>{item.question}</h3>
                                        <span style={{ fontSize: "24px" }}>{selected === i ? '-' : '+'}</span>
                                    </div>
                                    <div className={selected === i ? 'faq-answer show' : 'faq-answer'}>
                                        <p>{item.answer}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>





        </>

    )
}
