import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
    
        const [isSticky, setIsSticky] = useState(false);
      
        useEffect(() => {
          const handleScroll = () => {
            if (window.scrollY > 300) {
              setIsSticky(true);
            } else {
              setIsSticky(false);
            }
          };
      
          window.addEventListener('scroll', handleScroll);
      
          // Cleanup function to remove the event listener when the component unmounts
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);

    return (
        <>
          <div className=" top_header_menu container-fluid  ">
        <div className="row py-2 px-lg-5">
            <div className="col-lg-6  text-lg-left mb-2 mb-lg-0">
                <div className="d-inline-flex align-items-center text-white" style={{fontSize: "15px"}}>
                    <small><i className="fa fa-phone-alt " ></i>+91 6290382261</small>
                 
                    <small><i className="fa fa-envelope mr-2"style={{margin:" 0px 10px"}}></i>hr.roboanalytics@gmail.com</small>
                </div>
            </div>
            <div className="col-lg-6 text-end text-lg-right">
                <div className="d-inline-flex align-items-center">
                    {/* <a className="text-white px-2" href="">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="text-white px-2" href="">
                        <i className="fab fa-twitter"></i>
                    </a> */}
                    <a className="text-white px-2" href="https://www.linkedin.com/in/abhishek-bhattacharjee-78873a4b/" target="_blank">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                    {/* <a className="text-white px-2" href="">
                        <i className="fab fa-instagram"></i>
                    </a> */}
                    <a className="text-white px-2" href="https://www.youtube.com/channel/UCXQa4S8DQqzPVYt4YgJRdJw" target="_blank">
                        <i className="fab fa-youtube"></i>
                    </a>
                </div>
            </div>
        </div>
       </div>
            <div  className={` header sticky-top ${isSticky ? ' shadow-sm' : ''}`}
             style={{ top: isSticky ? '0px' : '-150px', transition: 'top 0.3s ease' }}
             >
           
                <div className="container ">
                    <nav className="navbar  navbar-expand-lg navbar-dark p-0">
                        <Link to="/" className="navbar-brand">
                            <img className="logo" style={{ height: "60px" }} src="img/logo.png" alt="" />
                            {/* <h1 className="text-light">Robo<span className="text-dark">.</span>Analytics</h1> */}
                        </Link>
                        <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav ms-auto">
                                <Link to="/" className="nav-item nav-link active">Home</Link>
                                <Link to="/our-services" className="nav-item nav-link">Our Services </Link>
                                <Link to="/career" className="nav-item nav-link">Career</Link>

                                {/* <Link to="/our-team" className="nav-item nav-link">Our Team </Link> */}

                                {/* <div className="nav-item dropdown">
                                    <Link to="/services" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">Our Ventures</Link>
                                    <div className="dropdown-menu bg-light mt-2">
                                        <Link to="/software_development" className="dropdown-item">Robo Analytics</Link>
                                        <Link to="/institution" className="dropdown-item">ABITM Institute</Link>
                                        <Link to="/construction" className="dropdown-item">Ganapati Construction</Link>
                                        <Link to="/hotel" className="dropdown-item">Hotel</Link>

                                    </div>
                                </div> */}
                               
                               {/* <Link to="#" className="nav-item nav-link">Blogs </Link> */}
                                <Link to="/contact" className="nav-item nav-link">Contact Us</Link>
                                {/* <butaton type="button" className="btn text-white p-0 d-none d-lg-block" data-bs-toggle="modal"
                                    data-bs-target="#searchModal"><i className="fa fa-search"></i></butaton> */}

                            </div>


                        </div>
                    </nav>
                </div>

            </div>
        </>
    )
}
