
import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodepen} from '@fortawesome/free-brands-svg-icons';

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Web_Development() {
    useEffect(() => {
        scrollToTop();
    }, []);

    const items = [
        {
              icon: faCodepen,
            title: 'Microservice Technologies',
            descr: ' ✦ Python\n ✦ Node js\n ✦ Express js\n ✦ Cors\n ✦ Socket.IO\n ',

            color: '#0D6EFD',
        },
        {
              icon: faCodepen,
            title: 'Comprehensive Testing',
            descr: '★ Mocha\n ★ Chai\n ★ PM2\n    ',
            color: '#6710F5',
        },
        {
              icon: faCodepen,
            title: 'Database Management',
            descr: '✦ SQL\n  ✦ MongoDB\n ',
            color: '#0D6EFD',
        },
        {
              icon: faCodepen,
            title: 'Web Technologies',
            descr: '★ Angular\n ★ React\n  ★ Webpack\n ★ Carbon Design System\n   ',
            color: '#6710F5',
        },

    ];
    return (
        <>
            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="section-title position-relative mb-4 pb-2">

                                <h1 className="mt-2">Web Development</h1>
                            </div>
                            <p className="mb-4">
                                Development
                                Our web development team makes sure that they stay up-to-date with the latest technological developments in this area so as to offer the best solutions in terms of technology. When you bring your business problem to us, we provide the best technology solutions suitable for your business. In combination with outstanding graphics, we help your business to make a positive impact on your target audience.</p>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Microservice Architecture: Python, Node.js</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Real-time Communication: Socket.IO</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Web Interface: Angular, React</h6>

                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Comprehensive Testing: Mocha, Chai, PM2</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Database Management: SQL, MongoDB</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Optimization: Webpack, Carbon Design</h6>

                                </div>
                            </div>

                            

                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid wow zoomIn"style={{height:"65vh"}}data-wow-delay="0.5s" src="img/It/web_dev.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="ol_header">

                    <h1 className="mt-2 ">OUR EXPERTISE</h1>
                </div>
                <div className='expertise_card'>
                    {items.map((item, index) => (
                        <li key={index} style={{ '--accent-color': item.color }}>
                            <div className="icon">
            <FontAwesomeIcon icon={item.icon} />
          </div>
                            <div className="title">{item.title}</div>
                            <div className="descr"> {item.descr.split('\n').map((line, i) => (
                                <div key={i}>{line}</div>
                            ))}</div>
                        </li>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Web_Development
