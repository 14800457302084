
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Our_services() {
    const [selected, setSelected] = useState(0);

    const services = [
        {
            title: "AI Research and Development",
            icon: "fa-robot",
            description: "AI tool and software development involves creating intelligent systems and applications that can perform tasks traditionally requiring human intelligence",
            link: "/ai",
            delay: "0.3s"
        },
        {
            title: "Cloud Solutions",
            icon: "fa-cloud",
            description: "We Deliver Cloud Solutions across major cloud platforms such as IBM Cloud, Google Cloud AI, Azure, and AWS.",
            link: "/cloud-solutions",
            delay: "0.6s"
        },
        {
            title: "Mobile App Development",
            icon: "fa-mobile",
            description: "Mobile app development involves the creation of software applications specifically designed to run on mobile devices such as smartphones and tablets.",
            link: "/mobile-app",
            delay: "0.6s"
        },
        {
            title: "Web & Software Development",
            icon: "fa-desktop",
            description: "Our web development team makes sure that they stay up-to-date with the latest technological developments in this area so as to offer the best solutions in terms of technology.",
            link: "/web-development",
            delay: "0.1s"
        },
        {
            title: "UX/UI Design",
            icon: "fa-laptop-code",
            description: "It has turned our UX/UI designers into experts in creating the most elegant and appealing UX Web Design.",
            link: "/ui-ux",
            delay: "0.1s"
        },
        {
            title: "Digital Marketing",
            icon: "fa-bullhorn",
            description: "Digital Marketing is all about creating an online presence and reaching out to your consumer’s screen to sell your products and services.",
            link: "/digital-marketing",
            delay: "0.3s"
        }
    ];
    const faqs = [
        { question: 'Who we are?', answer: 'We are the leading USA-based Web App Design & Development Company, creating scalable web applications, integrated with modern design frameworks and user-centric UI/UX. Hire web app developers to get top-notch quality web development services to boost performance. Explore more about us, and connect with our tech experts to have better insights.' },
        { question: 'What services do Robo Analytics provide?', answer: 'We offer digital marketing, web app development, UI/UX designs, AI, ML apps, cloud-based, graphic design, blockchain, and mobile app development.' },
        { question: 'What are the industries you work for?', answer: 'Robo Analytics, a top-ranked Web App Design & Development Company that delivers the following services with a well-versed and experienced team of experts: Real Estate, Healthcare, FoodTech, Travel & Hospitality, Automotive, Education, Retail & E-Commerce, Transportation, FinTech.' },
        { question: 'What approaches do you follow to deliver the project?', answer: 'Our team of web app developers adheres to the Agile Methodology that helps to select the right set of software for your business project, depending on the scope, and requirements, and supports us to deliver high-quality custom web app development solutions. 1-Ideation, 2-Development, 3-Testing, 4-Deployment, 5-Operations' },
        { question: 'How long does it take to develop and design a web app or mobile app?', answer: 'Our operations are time-bound and yet depending on the complexity, scope, and size of the project, the time frame can be decided. Through the help of our dedicated team of experts, we prioritize executing each project by ensuring quality and meeting all the requirements of the clients as well.' },
        { question: 'How much cost does it take for web app development?', answer: 'Depending on the project’s complexity, scope, size, and other essential factors help to decide the cost of web app development. To know the details of the cost structure for web app development, feel free to connect with our tech experts.' }
    ];
    const radius = 150; // Radius of the circle

    const technologies = [
        { src: "/img/services_icon/png/reactjs.png", alt: "React js" },
        { src: "/img/services_icon/png/Android_Studio.png", alt: "Android_Studio" },
        { src: "/img/services_icon/png/flask.png", alt: "flask" },
        { src: "/img/services_icon/png/python.png", alt: "python" },
        { src: "/img/services_icon/png/sprin.png", alt: "sprin" },
        { src: "/img/services_icon/png/js.png", alt: "js" },
        { src: "/img/services_icon/png/figma.png", alt: "figma" },
        { src: "/img/services_icon/png/php.png", alt: "PHP" },
        { src: "/img/services_icon/png/nodejs.png", alt: "NodeJS" },
        { src: "/img/services_icon/png/Google-Cloud.png", alt: "Google-Cloud" },
        { src: "/img/services_icon/png/css.png", alt: "css" },
        { src: "/img/services_icon/png/ps.png", alt: "ps" },
        { src: "/img/services_icon/png/xd.png", alt: "xd" },
        { src: "/img/services_icon/png/aws.png", alt: "aws" },
        { src: "/img/services_icon/png/java.png", alt: "java" },
        { src: "/img/services_icon/png/laravel.png", alt: "laravel" },
        { src: "/img/services_icon/png/t.png", alt: "t" },
        { src: "/img/services_icon/png/port.png", alt: "port" },



    ];



    const toggle = (i) => {
        // Toggle the clicked FAQ, including the first one
        setSelected(selected === i ? null : i);
    };

    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <div>
            <div className="  hero-header ">
                <div className="jumbotron_about jumbotron-fluid position-relative overlay-bottom" style={{ height: "500px" }}>
                    <div className="container pt-5 item">
                        <div className="row g-5 " style={{ paddingTop: '6rem' }}>
                            {/* <h1 className="text-white mt-5 mb-4">Learn From Robo Analytics</h1>
            <h1 className="text-white display-1 mb-5">Education Courses</h1> */}
                            <div className="col-lg-6" style={{ gap: '10px', alignItems: 'center' }}>

                                {/* <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">BG GROUP</div> */}
                                <h1 className=" text-white mt-1  mb-3 animated slideInRight">Services We Provide</h1>
                                <h1 className=" text-white mb-5 animated slideInRight">Our Capabilities</h1>

                            </div>

                            <div className="col-lg-6" style={{ position: 'relative', height: '352px', bottom: "6vh" }}>
                                {technologies.map((tech, index) => {
                                    // Define specific positions along with width and height for each image
                                    const positions = [
                                        { top: '10%', left: '5%', width: '60px', height: '60px' },
                                        { top: '44%', left: '34%', width: '70px', height: '70px' },
                                        { top: '15%', left: '60%', width: '80px', height: '80px' },
                                        { top: '52%', left: '2%', width: '50px', height: '50px' },
                                        { top: '50%', left: '80%', width: '75px', height: '75px' },
                                        { top: '10%', left: '40%', width: '60px', height: '60px' },
                                        { top: '86%', left: '8%', width: '55px', height: '50px' },
                                        { top: '80%', left: '70%', width: '55px', height: '55px' },
                                        { top: '0%', left: '18%', width: '85px', height: '85px' },
                                        { top: '62%', left: '15%', width: '80px', height: '80px' },
                                        { top: '35%', left: '22%', width: '45px', height: '45px' },
                                        { top: '40%', left: '56%', width: '38px', height: '70px' },
                                        { top: '26%', left: '90%', width: '38px', height: '60px' },
                                        { top: '78%', left: '45%', width: '70px', height: '70px' },
                                        { top: '56%', left: '50%', width: '80px', height: '80px' },
                                        { top: '74%', left: '94%', width: '50px', height: '50px' },
                                        { top: '0%', left: '75%', width: '137px', height: '90px' },
                                        { top: '76%', left: '28%', width: '60px', height: '60px' },

                                    ];

                                    // Get the current position for the image based on its index
                                    const { top, left, width, height } = positions[index % positions.length]; // Loop through defined positions

                                    return (
                                        <img
                                            key={index}
                                            src={tech.src}
                                            alt={tech.alt}
                                            style={{
                                                position: 'absolute',
                                                top: top,
                                                left: left,
                                                width: width, // Use the width from positions
                                                height: height, // Use the height from positions
                                                objectFit: 'contain', // Maintain aspect ratio
                                                transition: 'transform 0.3s',
                                                borderRadius: '10%', // Optional: rounded corners
                                            }}
                                            onMouseEnter={e => {
                                                e.currentTarget.style.transform = 'scale(1.1)'; // Scale image on hover
                                            }}
                                            onMouseLeave={e => {
                                                e.currentTarget.style.transform = 'scale(1)'; // Reset scale on mouse leave
                                            }}
                                        />
                                    );
                                })}
                            </div>









                        </div>
                    </div>

                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                        {/* <h2 className="mt-2">What Solutions We Provide</h2> */}
                    </div>
                    <div className="row g-4 mb-4">
                        {services.map((service, index) => (
                            <div key={index} className={`col-lg-4 col-md-6 wow zoomIn`} data-wow-delay={service.delay}>
                                <div className="service-item-it d-flex flex-column justify-content-center text-center rounded">
                                    <div className="service-icon flex-shrink-0">
                                        <i className={`fa ${service.icon} fa-2x`}></i>
                                    </div>
                                    <h5 className="mb-3">{service.title}</h5>
                                    <p>{service.description}</p>
                                    <Link className="btn px-3 mt-auto mx-auto" to={service.link}>Read More</Link>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="client-section">
                        <div className='client_section_text'>
                            More than <span className="highlight">100+ Happy</span> <br />  <span className="highlight">Clients</span> over <span className="highlight">10+ Years</span>  of work
                        </div>
                        <button className="cta-button">BECOME OUR CLIENT</button>
                    </div>

                    <div className=" row project-section">
                        <div className="project-content col-6">
                            <div className='project_content_text'>
                                We Deliver <span className="project_highlight">125 Web & Mobile<br /> Projects</span> per Year
                            </div>
                            <button className="cta-button">Start Project Now</button>
                        </div>
                        <div className="project-image col-6">
                            <img src="/img/img-we-deliver.png" alt="Laptop" />
                        </div>
                    </div>

                    {/* Faq Section */}
                    <div className="faq-container mt-5" style={{ width: '100%' }}>
                        <div className="row align-items-center">
                            {/* FAQ Title and Description */}
                            <div className="col-12 faq-header" style={{ textAlign: "center" }}>
                                <h1>Frequently Asked Questions</h1>
                                <p>Frequently asked question (FAQ) pages to find answers.</p>
                            </div>

                            {/* FAQ Questions and Answers */}
                            <div className="col-12 faq-content">
                                <div className="faq">
                                    {faqs.map((item, i) => (
                                        <div className="faq-item" key={i}>
                                            <div className="faq-question" onClick={() => toggle(i)}>
                                                <h3>{item.question}</h3>
                                                <span style={{ fontSize: "24px" }}>{selected === i ? '-' : '+'}</span>
                                            </div>
                                            <div className={selected === i ? 'faq-answer show' : 'faq-answer'}>
                                                <p>{item.answer}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>



        </div>
    )
}

export default Our_services
