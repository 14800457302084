import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

const Team = () => {
    const teamMembers = [
   
        {
            // name: "Rahul Shaw",
            title: "AI Research Head",
            imgSrc: "img/It/team1.png",
            delay: "0.3s",
            social: {
                facebook: "#",
                twitter: "#",
                instagram: "#",
                linkedin: "#"
            }
        },
        {
            // name: "Sumit Prasad",
            title: "Delivery Head ",
            imgSrc: "img/It/team2.jpg",
            delay: "0.6s",
            social: {
                facebook: "#",
                twitter: "#",
                instagram: "#",
                linkedin: "#"
            }
        }
    ];
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <div>

            <div className="  hero-header ">
                <div className="jumbotron_about jumbotron-fluid position-relative overlay-bottom" style={{ height: "655px" }}>
                    <div className="container pt-5 item">
                        <div className="row g-5 pt-5">

                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>
                            <a href="https://bhattacharjeegroup.com/"> <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">BG GROUP</div></a>

                                <h1 className=" text-white mt-1  mb-3 animated slideInRight">Robo Analytics</h1>

                                <h1 className=" text-white mt-1  mb-3 animated slideInRight">Our Team</h1>


                            </div>

                        </div>
                    </div>

                </div>
            </div>

            
            {/* team */}
            <div className="container-fluid  py-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            {/* <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Founder & CEO</div> */}
                            <h1 className="mb-4">Meet Our Founder & CEO</h1>
                            <p className="mb-4">Meet our Founder & CEO, who brings over 13 years of invaluable expertise to our organization. With a profound understanding of the industry cultivated over more than a decade, our leader has navigated the complexities of this field with unparalleled vision and determination. Their strategic insights and leadership have propelled our company to new heights, shaping our journey towards continued success.</p>
                            {/* <Link className="btn btn-primary rounded-pill px-4" to="">Read More</Link> */}
                        </div>
                        <div className="col-lg-6">
                            <div className="row g-6">
                                <div className="col-md-10">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                                            <div className="team-item  text-center rounded p-4 pt-0"style={{backgroundColor:"#faf5f5"}}>
                                                <img className="img-fluid rounded-circle p-4" src="img/team-1.png" alt="" />
                                                <h5 className="mb-0">Abhishek Bhattacharjee</h5>
                                                <small>Founder & CEO</small>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-facebook-f"></i></Link>
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-twitter"></i></Link>
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-instagram"></i></Link>
                                                    <Link className="btn btn-square btn-primary m-1" to=""><i
                                                        className="fab fa-linkedin-in"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">

                        <h2 className="mt-2">Meet Our Team Members</h2>
                    </div>
                    <div className="row g-4"style={{display:"flex",justifyContent:"center"}}>
                        {teamMembers.map((member, index) => (
                            <div key={index} className={`col-lg-4 col-md-6 wow fadeInUp`}  data-wow-delay={member.delay}>
                                <div className="team-item-it">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style={{ width: "75px" }}>
                                            <a className="btn btn-square text-primary bg-white my-1" href={member.social.facebook}><i className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-square text-primary bg-white my-1" href={member.social.twitter}><i className="fab fa-twitter"></i></a>
                                            <a className="btn btn-square text-primary bg-white my-1" href={member.social.instagram}><i className="fab fa-instagram"></i></a>
                                            <a className="btn btn-square text-primary bg-white my-1" href={member.social.linkedin}><i className="fab fa-linkedin-in"></i></a>
                                        </div>
                                        <div
                                            className="img-fluid rounded"
                                            style={{
                                                backgroundImage: `url(${member.imgSrc})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                width: '100%', // Adjust width as needed
                                                height: '425px' // Adjust height as needed
                                            }}
                                        ></div>
                                    </div>
                                    <div className="px-4 py-3">
                                        <h5 className="fw-bold m-0">{member.name}</h5>
                                        <small>{member.title}</small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team
