import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodepen} from '@fortawesome/free-brands-svg-icons';
const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Ui_Ux_Design() {
    useEffect(() => {
        scrollToTop();
    }, []);
    const items = [
        {
              icon: faCodepen,
            title: 'Visual Design',
            descr: ' ✦ Adobe XD  \n ✦ Sketch\n ✦ Figma\n ',

            color: '#0D6EFD',
        },
        {
              icon: faCodepen,
            title: 'Responsive Interfaces',
            descr: '★ HTML\n ★ CSS\n     ',
            color: '#6710F5',
        },
        {
              icon: faCodepen,
            title: 'Prototyping',
            descr: '✦ InVision\n  ✦ Zeplin\n ',
            color: '#0D6EFD',
        },
        {
              icon: faCodepen,
            title: 'Our Approach',
            descr:'★ Responsive Designs\n ★ User-friendly & User-interface Design Services \n  ★ Prototypes and Information Architecture\n ★ Flexible Hiring Models\n ★ Quality Processes\n  ',
            color: '#6710F5',
        },

    ];
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="section-title position-relative mb-4 pb-2">

                                <h1 className="mt-2">UX/UI Design</h1>
                            </div>
                            <p className="mb-4">
                                At Robo Analytics, our UX/UI design services merge cutting-edge technologies with innovative design principles to create seamless and captivating user experiences. Leveraging advanced tools such as Adobe XD, Sketch, and Figma, our expert designers craft visually stunning interfaces tailored to user preferences and behaviors. We integrate responsive design techniques using HTML, CSS, and JavaScript frameworks like Bootstrap and Materialize to ensure optimal performance across various devices and screen sizes. Additionally, our proficiency extends to utilizing prototyping tools like InVision and Zeplin to facilitate iterative design processes and user testing. With a focus on accessibility and usability, we adhere to WCAG guidelines and employ techniques such as A/B testing and heatmaps to refine designs based on user feedback and analytics.
                            </p>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Responsive Interfaces: HTML, CSS</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Prototyping: InVision, Zeplin</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i> User Testing</h6>



                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i> Adobe XD, Sketch, Figma</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i> Accessibility Compliance</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i> Iterative Refinement</h6>



                                </div>
                            </div>



                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid wow zoomIn" style={{height:"70vh"}} data-wow-delay="0.5s" src="img/It/UX-UI-Design.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="ol_header">

                    <h1 className="mt-2 ">OUR EXPERTISE</h1>
                </div>
                <div className='expertise_card'>
                    {items.map((item, index) => (
                        <li key={index} style={{ '--accent-color': item.color }}>
                            <div className="icon">
            <FontAwesomeIcon icon={item.icon} />
          </div>
                            <div className="title">{item.title}</div>
                            <div className="descr"> {item.descr.split('\n').map((line, i) => (
                                <div key={i}>{line}</div>
                            ))}</div>
                        </li>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Ui_Ux_Design
