
import './App.css';

import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import Header from './component/Header';
import It from './It/It';
import Footer from './component/Footer';
import Ai_Development from './It/Pages/Ai_Development';
import Cloud_Solution from './It/Pages/Cloud_Solution';
import Digital_Marketing from './It/Pages/Digital_Marketing';
import Mobile_App from './It/Pages/Mobile_App';
import Ui_Ux_Design from './It/Pages/Ui_Ux_Design';
import Web_Development from './It/Pages/Web_Development';

import Contact from './component/Contact';

import Team from './component/Team';
import Our_services from './It/Pages/Our_services';
import Career from './component/Career';

function App() {
  return (
   <>
  <Router>
    <Header/>
    <Routes>
      <Route path='/' element={<It/>}/>
      <Route path='/career' element={<Career/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/our-services' element={<Our_services/>}/>
      <Route path='/our-team' element={<Team/>}/>




      <Route path='/ai' element={<Ai_Development/>}/>
      <Route path='/cloud-solutions' element={<Cloud_Solution/>}/>
      <Route path='/digital-marketing' element={<Digital_Marketing/>}/>
      <Route path='/mobile-app' element={<Mobile_App/>}/>
      <Route path='/ui-ux' element={<Ui_Ux_Design/>}/>
      <Route path='/web-development' element={<Web_Development/>}/>


    </Routes>
    <Footer/>
  </Router>
   </>
  );
}

export default App;
