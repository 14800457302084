import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodepen} from '@fortawesome/free-brands-svg-icons';

const scrollToTop = () => {
    window.scrollTo(0, 0);
};


function Ai_Development() {

    useEffect(() => {
        scrollToTop();
    }, []);

    const items = [
        {
              icon: faCodepen,
            title: 'Data Types',
            descr: ' ✦ Numerical Data\n ✦ Time Series Data\n ✦ Structured Data\n ✦ Text Data\n ✦ Image Data (including Live Video analysis)',

            color: '#0D6EFD',
        },
        {
              icon: faCodepen,
            title: 'Use Cases',
            descr: '★ Chat Bot\n ★ GenAI\n ★ Text classification\n ★ Summarization\n ★ Auto-Correction\n ★ Language Translation\n ★ Image Classification\n ★ Object Detection   ',
            color: '#6710F5',
        },
        {
              icon: faCodepen,
            title: 'Model Training',
            descr: '✦ ElasticNet\n  ✦ Extra Tree Classifier, Random Forest\n ✦ LightGBM\n ✦ AdaBoost, Xgboost, CatBoost\n  ✦ Naive Bayes, SVC, KNN\n ✦ K-Means Clustering\n   ✦  CNN, RNN\n ✦  GRU, LSTM\n ✦ Bidirectional LSTM\n ✦ Bidirectional LSTM with custom Attention Layer',
            color: '#0D6EFD',
        },
        {
              icon: faCodepen,
            title: 'Model Fine',
            descr: '★ VGGNet\n ★ ResNet\n  ★ BERT\n ★ BART\n ★ t5-* ( base, small, large )\n ★ google/mt5-base\n ★ google/mt5-small\n  ★ Byt5\n ★ google/flan-t5-* ( base, small, large, xl, xxl )\n  ★ facebook/mbart-large-50-many-to-many-mmt\n ★ mBART50\n ★ marianmt  ',
            color: '#6710F5',
        },

    ];
  
    return (
        <div>
            <div className="container-xxl py-4">
                <div className="container px-lg-5">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="section-title position-relative mb-4 pb-2">

                                <h1 className="mt-2">AI Research and Development</h1>
                            </div>
                            <p className="mb-4">
                                At Robo Analytics, we harness an extensive array of state-of-the-art technologies to drive innovation across diverse domains.
                                Our expertise spans advanced data analysis and visualization techniques, encompassing feature engineering and selection.
                                In machine learning, we excel in regression, decision trees, random forests, AdaBoost, XGBoost, and various clustering algorithms.
                                Deep learning is a forte, with proficiency in artificial neural networks, convolutional neural networks, recurrent neural networks, and transformer architectures like BERT and BART.

                            </p>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i> Data Analysis and Visualization</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Machine Learning</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Deep Learning</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Gen AI</h6>


                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Transfer Learning</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Transformer Architectures</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>ML Tools</h6>
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>AI Tools</h6>


                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="img/It/ai.png" />
                        </div>
                    </div>
                </div>

            </div>

            <div>
                <div className="ol_header">

                    <h1 className="mt-2 ">OUR EXPERTISE</h1>
                </div>
                <div className='expertise_card'>
                    {items.map((item, index) => (
                        <li key={index} style={{ '--accent-color': item.color }}>
                            <div className="icon">
            <FontAwesomeIcon icon={item.icon} />
          </div>
                            <div className="title">{item.title}</div>
                            <div className="descr"> {item.descr.split('\n').map((line, i) => (
                                <div key={i}>{line}</div>
                            ))}</div>
                        </li>
                    ))}
                </div>
            </div>

        </div>


    )
}

export default Ai_Development
